import React, { Component } from 'react'
import {Card, Accordion, Container, Button, Row, Col, FormControl} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import Api from '../Api';
import { Multiselect } from 'multiselect-react-dropdown';
import SyncLoader from "react-spinners/SyncLoader";



class NewSchedule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            selectedCampaigns: [],
            selectedActivities: [],
            selectedFactivities:[],
            selectedDBname: "",
            changeOptions: false
        }
    }

    componentDidMount()
    {
      this.CheckAccess();

      Api.NewScheduleInfo().then((response) => {

              if (response != undefined) {
                  let responseJSON = JSON.parse(response);



                  if (!('no' in responseJSON)) { // If no is in response, then there is no access
                      this.setState({
                          allActivities: responseJSON.all_activities,
                          allFactivities: responseJSON.all_factivities,
                          allCampaigns: responseJSON.all_campaigns
                      })
                  }

              }
              this.setState({loading: false})
          })

    }

    CheckAccess = () =>
    {
      let refresh = localStorage.getItem('refresh-token')
      let access = localStorage.getItem('access-token')
      if (!access || !refresh)
      {
        window.location="/";
      }
    }

    editSchedule = (id) =>
    {
      localStorage.setItem("scheduleId", id)
      window.location.href = "/planner";

    }

    CreateSchedule = () =>
    {
      if(this.state.selectedDBname === "" || this.state.selectedCampaigns.length === 0)
      {
        this.setState({"initialError": true})
        return

      }
      let data = {
        "db_name": this.state.selectedDBname,
        "campaigns": this.state.selectedCampaigns,
        "activities": this.state.selectedActivities,
        "factivities": this.state.selectedFactivities,
      }
      Api.NewSchedule(data).then((response) =>
      {
        if (response != undefined) {
            let responseJSON = JSON.parse(response);
            if("success" in responseJSON)
            {
              localStorage.setItem("scheduleId", responseJSON.id)
              window.location.href = "/planner";
            }
            else
            {
              console.log("error")
            }
          }

      })

    }
    onSelectCampaign = (event) =>
    {
      this.setState({"selectedCampaigns": event});

    }
    onSelectActivity = (event) =>
    {
      this.setState({"selectedActivities": event});

    }
    onSelectFactivity = (event) =>
    {
      this.setState({"selectedFactivities": event});

    }

    UpdateDBname = (event) =>
    {
      this.setState({"selectedDBname": event.target.value});
    }

    changeHandler = event => {
        let value = event.target.value;

        this.setState(prevState => ({
          item: { ...prevState.item,  [event.target.name]: value }
        }))
      };

    render() {

        return (
          <div style={{backgroundColor: "#3b2e4d", minHeight: "100vh", }}>
          {this.state.loading ? <Container style={{backgroundColor: "3b2e4d"}}><Row style={{backgroundColor: "3b2e4d"}}><Col style={{color: "white", paddingTop: 300}}><SyncLoader color="white"/></Col></Row></Container>:
          <Container>
          <Row>
          <Col>
          <h1 style={{color: "white"}}>Nyt skema</h1>
          </Col>
          </Row>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
            integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
            crossorigin="anonymous"
          />
          <Row className="top-form-sec">
          <Col style={{textAlign: "left"}}>
          <FontAwesomeIcon onClick={() => window.location.href= "/schedules"} className="backButton" style={{ marginRight: "auto", fontSize: "2rem" }} icon={faChevronCircleLeft} />
          </Col>
          <Col>
          <h3>Database navn</h3>
          <FormControl
          style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
          aria-describedby="basic-addon1"
          maxlength="20"
          value={this.state.selectedDBname}
          onChange={this.UpdateDBname}
        />
          <h3>Kampagner</h3>
          <Multiselect
          name="selectedCampaigns"
          options={this.state.allCampaigns} // Options to display in the dropdown
          selectedValues={this.state.selectedCampaigns} // Preselected value to persist in dropdown
          onSelect={this.onSelectCampaign} // Function will trigger on select event
          onRemove={this.onSelectCampaign} // Function will trigger on remove event
          displayValue="org__Organization_name" // Property name to display in the dropdown options
          />
          {this.state.initialError? <p style={{color:"red"}}>Databasenavnet og mindst én kampagne skal vælges</p>: null }
          </Col>
          <Col>
          </Col>

          </Row>

          <Row className="form-sec">
          <Col>
          </Col>
          <Col>
          <h3>Fallback aktiviteter</h3>
          <p><i>Disse aktiviteter bliver brugt, hvis der ikke er nogle blok i skemaet på det pågældende tidspunkt.</i></p>
          <h4>Aktiviteter</h4>
          <Multiselect
          name="selectedActivities"
          options={this.state.allActivities} // Options to display in the dropdown
          selectedValues={this.state.selectedActivities} // Preselected value to persist in dropdown
          onSelect={this.onSelectActivity} // Function will trigger on select event
          onRemove={this.onSelectActivity} // Function will trigger on remove event
          displayValue="title_en" // Property name to display in the dropdown options
          />
          </Col>
          <Col>
          </Col>

          </Row>

          <Row className="form-sec">
          <Col>
          </Col>
          <Col>
          <h4>Featured aktiviteter</h4>
          <Multiselect
          name="selectedFactivities"
          options={this.state.allFactivities} // Options to display in the dropdown
          selectedValues={this.state.selectedFactivities} // Preselected value to persist in dropdown
          onSelect={this.onSelectFactivity} // Function will trigger on select event
          onRemove={this.onSelectFactivity} // Function will trigger on remove event
          displayValue="title_en" // Property name to display in the dropdown options
          />
          </Col>
          <Col>
          </Col>

          </Row>

          <Row className="bottom-form-sec">
          <Col>
          </Col>
          <Col>
          <Button onClick={() => this.CreateSchedule()}  style={{ verticalAlign: "middle", }}>Opret</Button>

          </Col>
          <Col>
          </Col>

          </Row>



          </Container>
          }

          </div>
        )

    }
}

export default NewSchedule
