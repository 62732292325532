import React, {Component} from 'react'
import {ButtonToolbar, ButtonGroup, Button, Row, Col, FormControl, Container} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faSave, faFileExport, faFileImport,faSearchPlus, faSearchMinus, faCog, faEye, faCopy, faBell } from '@fortawesome/free-solid-svg-icons'

class NavMenu extends React.Component
{
  constructor(props) {
     super(props);

    }

  render()
  {
    return (
      <Row style={{marginBottom: 5}}>
      <Col>
      <ButtonToolbar aria-label="Toolbar with button groups" style={{marginBottom: 5,}}>
      <ButtonGroup style={{ marginRight:"auto", }}>
          <Button style={{fontSize: "1rem"}} onClick={() => {window.location.href= "/schedules"}}>Oversigt</Button>
          <Button style={{fontSize: "1rem"}} onClick={() => {window.location.href= "https://easymove.running26.com/easyadmin/"}}>EasyAdmin</Button>
          <Button style={{fontSize: "1rem"}} onClick={() => {window.location.href= "/"}}>Log ud</Button>
          </ButtonGroup>
          <ButtonGroup style={{ marginLeft:"auto", }}>
              <Button style={{fontSize: "1rem"}} onClick={this.props.previewCampaign}><FontAwesomeIcon icon={faEye} /></Button>
              <Button style={{fontSize: "1rem"}} onClick={this.props.scheduleSettings}><FontAwesomeIcon icon={faCog} /></Button>
              </ButtonGroup>

      </ButtonToolbar>
      </Col>

      </Row>
    )
  }
}

export default NavMenu
