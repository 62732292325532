import React, {Component} from 'react'
import {ButtonToolbar, ButtonGroup, Button, Row, Col, FormControl, Container} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faSave, faFileExport, faFileImport,faSearchPlus, faSearchMinus, faCog, faCopy, faBell } from '@fortawesome/free-solid-svg-icons'

class PushInfoView extends React.Component
{
  constructor(props) {
     super(props);

    }

  render()
  {
    return (
      <Row>

        <Col>
        <h5>Dansk notifikation</h5>
        Titel
        <FormControl
        style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
        aria-describedby="basic-addon1"
        maxlength="20"
        value={this.props.selectedPushTitleDa}
        disabled="true"
        onChange={(event)=>{
          this.props.selectedEvent.setExtendedProp("title_da", event.target.value)
          this.props.updateState({selectedPushTitleDa: event.target.value})
        }}
      />
      Besked
      <FormControl
      style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
      aria-describedby="basic-addon1"
      as="textarea"
      maxlength="250"
      value={this.props.selectedMsgDa}
      disabled="true"
      onChange={(event)=>{
        this.props.selectedEvent.setExtendedProp("msg_da", event.target.value)
        this.props.updateState({selectedMsgDa: event.target.value})
      }}
    />
    {this.props.selectedEvent.extendedProps.displayColors.map((item)=>
      {
        return <div style={{width: 25, height: 25, backgroundColor: item, marginLeft: 5, marginRight: "auto", marginTop: 10, borderRadius: 6, display: "inline-block"}}></div>

      }) }


        </Col>
        <Col>
        <h5>Engelsk notifikation</h5>
        Titel
        <FormControl
        style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
        aria-describedby="basic-addon1"
        maxlength="20"
        value={this.props.selectedPushTitleEn}
        disabled="true"
        onChange={(event)=>{
          this.props.selectedEvent.setExtendedProp("title_en", event.target.value)
          this.props.updateState({selectedPushTitleEn: event.target.value})
        }}
      />
      Besked
      <FormControl
      style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
      aria-describedby="basic-addon1"
      as="textarea"
      maxlength="250"
      disabled="true"
      onChange={(event)=>{
        this.props.selectedEvent.setExtendedProp("msg_en", event.target.value)
        this.props.updateState({selectedMsgEn: event.target.value})
      }}
      value={this.props.selectedMsgEn}
    />
        </Col>
      </Row>
    )
  }
}

export default PushInfoView
