import React, { Component } from 'react'
import {Card, Accordion, Container, Button, Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import SyncLoader from "react-spinners/SyncLoader";
import Api from '../Api';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class Lister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
    }

    componentDidMount()
    {
      this.CheckAccess();

      Api.getSchedules().then((response) => {

              if (response != undefined) {
                  let responseJSON = JSON.parse(response);



                  if (!('no' in responseJSON)) { // If no is in response, then there is no access
                      this.setState({
                          schedules: responseJSON.schedules
                      })
                  }

              }
              this.setState({loading: false})
          })

    }

    CheckAccess = () =>
    {
      let refresh = localStorage.getItem('refresh-token')
      let access = localStorage.getItem('access-token')
      if (!access || !refresh)
      {
        window.location="/";
      }
    }

    editSchedule = (id) =>
    {
      localStorage.setItem("scheduleId", id)
      window.location.href = "/planner";

    }
    deleteSchedule = (id, s) =>
    {
      localStorage.setItem("scheduleId", id)
      confirmAlert({
      title: 'Slet skema',
      message: 'Er du sikker på at du vil slette ' + s + "?",
      buttons: [
        {
          label: 'Ja',
          onClick: () =>
          {
            this.setState({"loading": true})
            Api.deleteSchedule(localStorage.getItem("scheduleId")).then((response) =>
            {
              window.location="/schedules"
            });
          }
        },
        {
          label: 'Nej',
        }
      ]
    });

    }

    createSchedule = () =>
    {
      window.location.href = "/schedules-new/";
    }

    render() {
        return (
          <div style={{backgroundColor: "#3b2e4d", minHeight: "100vh", }}>
          {this.state.loading ? <Container style={{backgroundColor: "3b2e4d"}}><Row style={{backgroundColor: "3b2e4d"}}><Col style={{color: "white", paddingTop: 300}}><SyncLoader color="white"/></Col></Row></Container>:
          <Container>
          <Row>
          <Col>
          <h1 style={{color: "white"}}>Skemaer</h1>
          </Col>
          </Row>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
            integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
            crossorigin="anonymous"
          />
          <Accordion>
          {this.state.schedules !== null ? this.state.schedules.map((s) => { return <div>

              <Card >
                <Card.Header>
                <Row>
                <Col style={{textAlign: "left"}}>
                  <Accordion.Toggle as={Button} variant="link" style={{color:"black", fontSize: "1rem"}}eventKey={s.id}>
                    <strong>{s.name}</strong>
                  </Accordion.Toggle>
                  </Col>
                <Col style={{textAlign: "right"}}>
                  <Button style={{fontSize: "1rem", marginLeft:"auto", textAlign: "right"}} onClick={() => {this.editSchedule(s.id)}}>Redigér</Button>
                  <Button style={{fontSize: "1rem", marginLeft:"0.6rem", textAlign: "right"}} onClick={() => {this.deleteSchedule(s.id, s.name)}}>
                  <FontAwesomeIcon icon={faTrashAlt}/>
</Button>
                  </Col>
                  </Row>
                </Card.Header>
                <Accordion.Collapse eventKey={s.id}>
                  <Card.Body>
                  <Row>
                  <Col>
                  <h4>Kampagner</h4>
                  {s.campaigns.map((c) => { return <p>{c.org__Organization_name}</p>})}
                  </Col>


                  </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              </div>
            }) : <h3 style={{color:"white"}}>Der er ingen skemaer</h3>}
            </Accordion>
            <FontAwesomeIcon className="plusIcon" icon={faPlusCircle} onClick={this.createSchedule}/>

          </Container>
          }
          <div className="linkText">
          <a href="https://easymove.running26.com/easyadmin/" >Tilbage til easyadmin</a>
          </div>
          </div>
        )
    }
}

export default Lister
