import React, {Component} from 'react'
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all"
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import {ButtonToolbar, ButtonGroup, Button, Row, Col, FormControl, Container} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faSave, faFileExport, faFileImport,faSearchPlus, faSearchMinus, faCog,faEye, faCopy, faBell } from '@fortawesome/free-solid-svg-icons'
import { CirclePicker} from 'react-color';
import { Multiselect } from 'multiselect-react-dropdown';
import Api from '../Api'
import DatetimeRangePicker from 'react-datetime-range-picker';
import 'moment/locale/da';
import Searchable from 'react-searchable-dropdown';
import EditSchedule from "./edit-schedule-page"
import {CampaignCard, NavMenu, PushInfoView, UtilityButtons, TemplateSearch,} from "./components"
import moment from 'moment';



class Planner extends Component {
  constructor() {
    super()
    this.state = {
      calendar: null,
      selectedEvent: null,
      selectedTitle: null,
      templates: [{"id": null, "title": "Ingen templates"},],
      selectedActivities: null,
      selectedFactivities: null,
      selectedBonus: null,
      pickColor: false,
      durationZoom: 1,
      allActivities: [],
      allFactivities: [],
      allBonus: [],
      isLoading: true,
      isTemplating: false,
      existingBlocks: [],
      selectedTemplate: null,
      selectedMsgDa: "",
      selectedMsgEn: "",
      selectedPushTitleDa: "",
      selectedPushTitleEn: "",
      campaigns: [],



    }
  }




  CheckAccess = () =>
  {
    let refresh = localStorage.getItem('refresh-token')
    let access = localStorage.getItem('access-token')
    if (!access || !refresh)
    {
      window.location="/";
    }
  }
  componentDidMount()
  {
    this.CheckAccess();

    this.getData();

    var calendarEl = document.getElementById('calendar');
    let draggableEl = document.getElementById('mydraggable');

    var calendar = new Calendar(calendarEl, {
      plugins: [ timeGridPlugin, interactionPlugin],
      locales: allLocales,
      locale: "da",
      allDaySlot: false,
      slotDuration: "01:00:00",
      slotLabelInterval: "02:00:00",
      slotLabelFormat:{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short'
      },
      themeSystem: 'standard',
      initialView: 'timeGridWeek',
      selectable: true,
      editable: true,
      select: this.DidSelect,
      eventClick: this.DidClick,
      contentHeight: "auto",
      eventClick: this.DidClick,
      nowIndicator: true,
      eventContent: function (args, createElement) {
        let e_props = args.event.extendedProps
        let activity_info = ""
        let factivity_info = ""
        if(e_props.activities)
        {
          if(e_props.activities.length > 0)
          {
            activity_info= "aktiviteter: " + e_props.activities.length

          }

        }
        if(e_props.factivities)
        {
          if(e_props.factivities.length > 0)
          {
            factivity_info= "Featured aktiviteter: " + e_props.factivities.length

          }

        }


  if(e_props.type === "push")
  {
    return {
      html: "<i>" + args.timeText.split(" -")[0] + "</i> " + "<strong>" + args.event._def.title +"</strong><br/>"

    };
  }
  else
  {
    return {
      html: "<i>" + args.timeText + "</i>" + "</br>"+
            "<strong>" + args.event._def.title +"</strong><br/>"+
            activity_info + "<br/>"+
            factivity_info
    };
  }

},
    });

    calendar.render();
    new Draggable(draggableEl,
      {
        eventData: {
        title: 'Ny blok',
        duration: '04:00',
        extendedProps:{"id": null, "type":"activity"},
        backgroundColor: "#007bff"

        }
      });
    let draggableTempEl = document.getElementById('template-draggable');
    new Draggable(draggableTempEl,
      {
        eventData: this.getTempData
      });
      /*
      let notiEl = document.getElementById('noti-draggable');
      new Draggable(notiEl,
        {
          eventData: {
          title: 'Push',
          duration: '01:00',
          extendedProps:{"id": null, "type":"push", "title_en": "", "title_da": "", "msg_en": "", "msg_da":  ""},
          backgroundColor: "#940f9c",
          borderColor: "#940f9c",
          durationEditable: false,


          }
        });
        */
    calendar.updateSize();
    this.setState({"calendar": calendar})
  }

  getTempData = () => {
    let new_event =  {
    title: 'Ny blok',
    type:"activity",
    activities: [],
    factivities: [],
    bonus: [],
    color: "#007bff"
  }


    if (this.state.templates !== null && this.state.selectedTemplate !== null)
    {
      new_event = this.state.templates.find(o => o.id === this.state.selectedTemplate)

    }


    return {
    title: new_event.title,
    duration: '04:00',
    extendedProps:{"id": null, "activities": new_event.activities, "factivities": new_event.factivities, "bonus": new_event.bonus, "type":"activity",},
    backgroundColor: new_event.color

  }


  }

  getCopyData = () => {
    let new_event =  {
    title: 'Ny blok',
    activities: [],
    factivities: [],
    bonus: [],
    duration: '04:00',
    color: "#007bff"
  }

    if (this.state.selectedEvent)
    {
      if(this.state.selectedEvent.extendedProps.type == "activity")
      {
        let se = this.state.selectedEvent
        let new_acts = se.extendedProps.activities
        let new_facts = se.extendedProps.factivities
        let new_bonus = se.extendedProps.bonus
        new_event =
        {
          title: se.title,
          color: se.backgroundColor,
          duration: '04:00',
          type: "activity",
          activities: new_acts,
          factivities: new_facts,
          bonus: new_bonus,

        }
        console.log(new_event)

      }

    }
    return new_event



  }


  getData = () =>
  {
    this.setState({isLoading: true})
    Api.getInfo().then((response) => {
            if (response != undefined) {
                let responseJSON = JSON.parse(response);
                if (!('no' in responseJSON)) { // If no is in response, then there is no access
                    this.setState({
                        allActivities: responseJSON.all_activities,
                        allFactivities: responseJSON.all_factivities,
                        allBonus: responseJSON.all_bonus,
                        templates: responseJSON.templates,
                        scheduleId: responseJSON.schedule.id,
                        scheduleName: responseJSON.schedule.name,
                        campaigns: responseJSON.campaigns,
                        isSaving: false,
                        pushMessages: responseJSON.schedule.push_messages,
                    })
                    console.log(responseJSON)
                    if(responseJSON.schedule != null)
                    {
                      let existingBlocks = responseJSON.schedule.blocks;
                      if (existingBlocks.length > 0)
                      {
                        if(existingBlocks.length > 0){
                        for(let i = 0; i< existingBlocks.length; i++)
                        {
                          this.state.calendar.addEvent({
                            title: existingBlocks[i].title, // a property!
                            start: existingBlocks[i].start, // a property!
                            end: existingBlocks[i].end,
                            backgroundColor: existingBlocks[i].color,
                            borderColor: existingBlocks[i].color,
                            extendedProps:
                            {
                              type: "activity",
                              block_id: existingBlocks[i].id,
                              activities: existingBlocks[i].activities,
                              factivities: existingBlocks[i].featured_activities,
                              bonus: existingBlocks[i].bonus,

                            }
                          })
                          console.log("Done")
                        }
                        }
                      }

                      let pushesCampaigns = responseJSON.schedule.push_messages
                      if(pushesCampaigns)
                      {
                        let pushData = []
                        pushesCampaigns.map((camp, index) =>
                        {
                          if(camp)
                          {
                            for(let n = 0; n < camp.length; n++)
                            {
                              let new_hex = "#" + this.GetCampaignColor(this.state.campaigns[index].name)
                              console.log(new_hex)
                              pushData.push({
                                title: 'Push',
                                start: camp[n].time_to_send, // a property!
                                duration: '01:00',
                                backgroundColor: new_hex,
                                borderColor: new_hex,
                                extendedProps:
                                {
                                  id: null,
                                  type: "push",
                                  title_en: camp[n].title_en,
                                  title_da: camp[n].title_da,
                                  msg_en: camp[n].message_en,
                                  msg_da:  camp[n].message_da,
                                  displayColors: [new_hex,]
                                },
                                durationEditable: false,
                                editable: false,
                              })

                            }

                          }

                        })
                        let distinctEvents = []
                        for(let i = 0; i < pushData.length; i++)
                        {
                          let matches = distinctEvents.filter((item)=> item.start === pushData[i].start &&
                                                                       item.extendedProps.title_en === pushData[i].extendedProps.title_en &&
                                                                       item.extendedProps.title_da === pushData[i].extendedProps.title_da &&
                                                                       item.extendedProps.msg_en === pushData[i].extendedProps.msg_en &&
                                                                       item.extendedProps.msg_da === pushData[i].extendedProps.msg_da
                                                                     )
                          if(matches.length < 1)
                          {
                            distinctEvents.push(pushData[i])
                            console.log(pushData[i])
                          }
                          else
                          {
                            let existingIndex = distinctEvents.indexOf(matches[0])
                            distinctEvents[existingIndex].backgroundColor= "#ffffff";
                            distinctEvents[existingIndex].borderColor= "red";
                            distinctEvents[existingIndex].textColor= "black";
                            distinctEvents[existingIndex].title= "push";
                            distinctEvents[existingIndex].extendedProps.displayColors.push(pushData[i].backgroundColor);

                          }

                        }

                        console.log(distinctEvents)
                        distinctEvents.map((item)=>
                        {
                          this.state.calendar.addEvent(item)
                        })
                      }
                      let camps = responseJSON.campaigns;
                      if(camps)
                      {
                        for(var i = 0; i < camps.length; i++)
                        {
                          for(var n = -25; n < 26; n++)
                          {
                            console.log(camps[i].open_days)
                            var start = moment(this.state.calendar.getDate()).add(n, 'day').set({hour:camps[i].open_start.split(":")[0],minute:camps[i].open_start.split(":")[1],second:0,millisecond:0})
                            var end = moment(this.state.calendar.getDate()).add(n, 'day').set({hour:camps[i].open_end.split(":")[0],minute:camps[i].open_end.split(":")[1],second:0,millisecond:0})


                            let new_hex = "#" + this.GetCampaignColor(camps[i].name)
                            if(camps[i].open_days[start.day()])
                            {
                              this.state.calendar.addEvent({
                                start: start.format('YYYY-MM-DD HH:mm'),
                                end: end.format('YYYY-MM-DD HH:mm'),
                                display: 'background',
                                backgroundColor: "#8597ff",
                                borderColor: "#8597ff",
                                extendedProps:
                                {
                                  type: "open_hours",
                                }
                              })
                            }

                          }
                        }
                      }



                }

            }
            this.setState({isLoading: false})
          }
        })
  }

  SaveCalendar = async () =>
  {
    const events = this.state.calendar.getEvents()
    const filteredEvents = events.filter(item => item.extendedProps.type === "activity")
    let data = {"id": this.state.scheduleId, "events": filteredEvents}
    if(!this.state.isSaving){
    this.setState({"isSaving": true})
    Api.updateInfo(data).then((response) =>
    {
      console.log(response);
      this.setState({"isSaving": false})
    });
    }

  }

  ZoomCalendar = (zoomIn) =>
  {
    const possibleZooms = [0.25,0.5,1,2,3,4]
    var currentZoom = this.state.durationZoom
    var currentZoomIndex = possibleZooms.indexOf(currentZoom)
    if(zoomIn)
    {
      var newIndex = possibleZooms.indexOf(currentZoom) -1;
      if (newIndex >= 0)
      {
        var newZoom = possibleZooms[newIndex];
        this.setState({"durationZoom": newZoom})

        if (newZoom < 1)
        {
          var minutes = 60 * newZoom
          this.state.calendar.setOption("slotDuration", "00:"+minutes+":00");
          this.state.calendar.setOption("slotLabelInterval", "00:"+(minutes*2)+":00");

        }
        else
        {
          var hours = newZoom
          this.state.calendar.setOption("slotDuration", hours+":00:00");
          this.state.calendar.setOption("slotLabelInterval", (hours*2)+":00:00");
        }

      }
    }
    else
    {
      var newIndex = possibleZooms.indexOf(currentZoom) +1;
      if (newIndex <= possibleZooms.length -1)
      {
        var newZoom = possibleZooms[newIndex];
        this.setState({"durationZoom": newZoom})

        if (newZoom < 1)
        {
          var minutes = 60 * newZoom
          this.state.calendar.setOption("slotDuration", "00:"+minutes+":00");
          this.state.calendar.setOption("slotLabelInterval", "00:"+(minutes*2)+":00");

        }
        else
        {
          var hours = newZoom
          this.state.calendar.setOption("slotDuration", hours+":00:00");
          this.state.calendar.setOption("slotLabelInterval", (hours*2)+":00:00");
        }

      }
    }
    this.state.calendar.updateSize();
  }

  DidSelect = (selectionInfo) =>
  {
    console.log(selectionInfo);
    this.setState({"selectedEvent": null})
    this.setState({"selectedTitle": null});
    this.setState({"selectedActivities": null});
    this.setState({"selectedFactivities": null});
    this.setState({"selectedBonus": null});
    this.SaveCalendar()



  }

  DidClick = (ClickInfo) =>
  {
    this.DidSelect()
    if(ClickInfo.event.extendedProps.type === "activity")
    {
      this.setState({"selectedEvent": ClickInfo.event});
      this.setState({"selectedTitle": ClickInfo.event.title});
      this.setState({"selectedActivities": ClickInfo.event.extendedProps.activities});
      this.setState({"selectedFactivities": ClickInfo.event.extendedProps.factivities});
      this.setState({"selectedBonus": ClickInfo.event.extendedProps.bonus});

      let draggableCopyEl = document.getElementById('copy-draggable');
      new Draggable(draggableCopyEl,
        {
          eventData: this.getCopyData
        });
      console.log(ClickInfo);

    }
    else if(ClickInfo.event.extendedProps.type === "push")
    {
      this.setState({"selectedEvent": ClickInfo.event});
      this.setState({"selectedTitle": ClickInfo.event.title});
      this.setState({"selectedMsgDa": ClickInfo.event.extendedProps.msg_da});
      this.setState({"selectedMsgEn": ClickInfo.event.extendedProps.msg_en});
      this.setState({"selectedPushTitleDa": ClickInfo.event.extendedProps.title_da});
      this.setState({"selectedPushTitleEn": ClickInfo.event.extendedProps.title_en});
    }


  }
  CreateTemplate = async () =>
  {
    this.setState({"isTemplating": true})
    let targetTemp = this.state.selectedEvent;
    Api.createTemplate(targetTemp).then((response) =>
    {
      this.setState({"isTemplating": false})
      window.location="/planner"
    });

  }

  UpdateTitle = (event) =>
  {
    this.state.selectedEvent.setProp("title", event.target.value)

    this.setState({"selectedTitle": event.target.value});

  }

  DeleteEvent = (e) =>
  {
    this.state.selectedEvent.remove()
    this.DidSelect();

  }

  DeleteTemplate = (e) =>
  {
    Api.deleteTemplate(this.state.selectedTemplate).then((response) =>
    {
      this.setState({"isTemplating": false})
      window.location="/planner"
    });


  }
  previewCampaign = () =>
  {
      window.open("https://easymove.running26.com/easyadmin/preview-campaign/" + this.state.campaigns[0].id, "_blank") ;
  }

  previewBlock = () =>
  {
    // %Y-%m-%dT%H:%M
    var date = moment(this.state.selectedEvent.start).format('YYYY-MM-DD\THH:MM');
    console.log(date);
    window.open("https://easymove.running26.com/easyadmin/preview-campaign/" + this.state.campaigns[0].id + "?time="+date, "_blank") ;

  }

  handleColorChange = (color, event) =>
  {
    this.state.selectedEvent.setProp("backgroundColor", color.hex)
    this.state.selectedEvent.setProp("borderColor", color.hex)
    this.setState({"pickColor": false});

  }

  onSelectActivity = (event) =>
  {
    this.setState({"selectedActivities": event});
    this.state.selectedEvent.setExtendedProp("activities", event)

  }
  onSelectFactivity = (event) =>
  {
    this.setState({"selectedFactivities": event});
    this.state.selectedEvent.setExtendedProp("factivities", event)

  }
  onSelectBonus = (event) =>
  {
    this.setState({"selectedBonus": event});
    this.state.selectedEvent.setExtendedProp("bonus", event)

  }

  SelectTemplate = (event) =>
  {
    this.setState({"selectedTemplate": event.value})
  }

  GetCampaignColor = (str) =>
  {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
       hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;

  }

  render() {
    if (this.state.changeOptions)
    {
      return <EditSchedule/>
    }
    else{
     return (
      <div className="main-container">
      <h2 style={{color: "white", marginBottom: 20}}>
      {this.state.scheduleName === null ?
      "Campaign planner"
      :
      this.state.scheduleName
      }
      </h2>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />
      <div className="toolbar">
      <div style={{marginBottom: 10}}>
      {this.state.campaigns.map((item, index)=>
        {
          return <CampaignCard item={item} index={index} cardColor={() => { return this.GetCampaignColor(item.name)}} campaigns={this.state.campaigns}/>

        })}

      </div>
      <NavMenu scheduleSettings={() => {this.setState({"changeOptions": true})}} previewCampaign={this.previewCampaign}/>
      <Row>
      <Col>
      <ButtonToolbar aria-label="Toolbar with button groups" style={{marginBottom: 5}}>
        <ButtonGroup aria-label="First group">
          <Button id="mydraggable" style={{fontSize: "1rem"}}>Ny blok</Button>

        </ButtonGroup>
        {/*
        <Button id="noti-draggable" style={{ verticalAlign: "middle", marginLeft: 3 }}>
        {this.state.isTemplating ? "..." : <FontAwesomeIcon icon={faBell} />}</Button>*/}
        <Button id="template-draggable" style={{ verticalAlign: "middle", marginLeft: 3 }}>
        {this.state.isTemplating ? "..." : <FontAwesomeIcon icon={faFileImport} />}</Button>
        {this.state.selectedTemplate ?
        <Button caption="Slet template" onClick={this.DeleteTemplate} style={{marginLeft: 3, }}>
        <FontAwesomeIcon icon={faTrashAlt} /></Button>
        :
        null
        }
      </ButtonToolbar>
      <TemplateSearch templates={this.state.templates} select={this.SelectTemplate}/>


      </Col>
      <Col xs={7}>
      {this.state.selectedEvent === null ?
      <div>
            <div>Tryk på en blok for at redigere i den.</div>
      </div>
      :
      this.state.selectedEvent.extendedProps.type === "activity" ?
      <div className="infoView">
      <Row>
      <Col>
      Titel
      <FormControl
      style={{ marginLeft: "auto !important", marginRight: "auto !important"}}
      aria-describedby="basic-addon1"
      maxlength="20"
      value={this.state.selectedTitle}
      onChange={this.UpdateTitle}
    />

    {this.state.pickColor ?
    <div style={{backgroundColor: "white", borderRadius: "1rem",zIndex: "2", position:"relative", width: "fit-content", padding: 5}}>
    <CirclePicker
    onChange={ this.handleColorChange }
    />

    </div>
    :
    <div>
    {this.state.selectedEvent.backgroundColor ?
      <div className="color-square" style={{backgroundColor: this.state.selectedEvent.backgroundColor}} onClick={() => this.setState({"pickColor": true})}></div>
      :
    <div className="color-square" style={{backgroundColor: "#3888d8"}} onClick={() => this.setState({"pickColor": true})}></div>
    }
    </div>
  }

      </Col>
      <Col>
      Aktiviteter
      <Multiselect
      options={this.state.allActivities} // Options to display in the dropdown
      selectedValues={this.state.selectedActivities} // Preselected value to persist in dropdown
      onSelect={this.onSelectActivity} // Function will trigger on select event
      onRemove={this.onSelectActivity} // Function will trigger on remove event
      displayValue="title_en" // Property name to display in the dropdown options
      style={{chips: {
        width: "100%",
        overflow: "hidden",
        overflowWrap: "anywhere",
        whiteSpace: 'pre-wrap',
        backgroundColor: "#3888d8"

      }}}
      />

      </Col>
      <Col>
      Featured aktiviteter
      <Multiselect
      options={this.state.allFactivities} // Options to display in the dropdown
      selectedValues={this.state.selectedFactivities} // Preselected value to persist in dropdown
      onSelect={this.onSelectFactivity} // Function will trigger on select event
      onRemove={this.onSelectFactivity} // Function will trigger on remove event
      displayValue="title_en" // Property name to display in the dropdown options
      style={{chips: {
        width: "100%",
        overflow: "hidden",
        overflowWrap: "anywhere",
        whiteSpace: 'pre-wrap',
        backgroundColor: "#3888d8"

      }}}
      />

      </Col>
      <Col>
      Bonus objekter
      <Multiselect
      options={this.state.allBonus} // Options to display in the dropdown
      selectedValues={this.state.selectedBonus} // Preselected value to persist in dropdown
      onSelect={this.onSelectBonus} // Function will trigger on select event
      onRemove={this.onSelectBonus} // Function will trigger on remove event
      displayValue="name" // Property name to display in the dropdown options
      style={{chips: {
        width: "100%",
        overflow: "hidden",
        overflowWrap: "anywhere",
        whiteSpace: 'pre-wrap',
        backgroundColor: "#3888d8"

      }}}
      />

      </Col>
      </Row>
      </div>
      :
      this.state.selectedEvent.extendedProps.type === "push"?
      <PushInfoView {...this.state} updateState={(update)=>{this.setState(update)}}/>
      :
      null
    }


      </Col>
      <UtilityButtons ZoomCalendar={this.ZoomCalendar} SaveCalendar={this.SaveCalendar} isSaving={this.state.isSaving}/>
      </Row>
      {this.state.selectedEvent === null ? null :
        this.state.selectedEvent.extendedProps.type === "activity"?
      <Row>
      <Col>
      <Button onClick={this.previewBlock} style={{ verticalAlign: "middle", marginTop:"0.5rem", marginRight: 3 }}>
      <FontAwesomeIcon  icon={faEye} /></Button>
      <Button id="copy-draggable" style={{ verticalAlign: "middle", marginTop:"0.5rem", marginRight: 3 }}>
      <FontAwesomeIcon  icon={faCopy} /></Button>
      <Button className="button-primary" onClick={this.CreateTemplate}  style={{ verticalAlign: "middle", marginTop:"0.5rem", marginRight: 3 }}>
      {this.state.isTemplating ? "..." : <FontAwesomeIcon icon={faFileExport} />}</Button>
      <Button className="button-danger" onClick={this.DeleteEvent}  style={{ verticalAlign: "middle", marginTop:"0.5rem" }}>
      <FontAwesomeIcon icon={faTrashAlt} /></Button>
      </Col>
      </Row>
      :
      null
    }
      </div>
        <div id="calendar"/>
      </div>
    )
  }
}
}

export default Planner
