// const ApiBaseUrl = "https://easymove-be.herokuapp.com";
const ApiBaseUrl = process.env.REACT_APP_BACKEND_URL;
// const ApiBaseUrl = "https://easymove-staging.herokuapp.com";
// const ApiBaseUrl = "http://192.168.0.22:8000";

const Api = {
  NewSchedule: (data) => {
    return fetchFromServer('POST', 'easyadmin/schedules/new/', data);
  },
  NewScheduleInfo: () => {
    return fetchFromServer('GET', 'easyadmin/schedules/new/');
  },
  EditScheduleInfo: () => {
    return fetchFromServer(
      'GET',
      'easyadmin/schedules/edit/' + localStorage.getItem('scheduleId') + '/'
    );
  },
  EditSchedule: (data) => {
    return fetchFromServer(
      'POST',
      'easyadmin/schedules/edit/' + localStorage.getItem('scheduleId') + '/',
      data
    );
  },
  getInfo: () => {
    return fetchFromServer(
      'GET',
      'easyadmin/planner/' + localStorage.getItem('scheduleId') + '/'
    );
  },
  getSchedules: () => {
    return fetchFromServer('GET', 'easyadmin/schedules/');
  },
  updateInfo: (data) => {
    return fetchFromServer(
      'POST',
      'easyadmin/planner/' + localStorage.getItem('scheduleId') + '/',
      data
    );
  },
  createTemplate: (data) => {
    return fetchFromServer('POST', 'easyadmin/planner-template/', data);
  },
  deleteTemplate: (data) => {
    return fetchFromServer('DELETE', 'easyadmin/planner-template/', data);
  },
  deleteSchedule: (data) => {
    return fetchFromServer('DELETE', 'easyadmin/schedules/', data);
  },
  getDetails: () => {
    return fetchFromServer('GET', 'dash/v2/api/');
  },
  getToken: (username, password) => {
    return new Promise((resolve) => {
      resolve(getToken(username, password));
    });
  },
};

function getFormatFromDate(date) {
  return (
    ('0' + date.getDate()).slice(-2) +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    date.getFullYear() +
    ('0' + date.getHours()).slice(-2) +
    ('0' + date.getMinutes()).slice(-2)
  );
}

const getToken = (username, password) => {
  // let url = "https://easymove-be.herokuapp.com/dashapi/token/"
  // let url ="https://easymove-staging.herokuapp.com/dashapi/token/"
  let url = ApiBaseUrl + '/dashapi/token/';
  let data = {
    username,
    password,
  };

  let options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, options).then((response) => {
    switch (response.status) {
      case 200:
        console.log('Status: 200 \t Obtaining tokens successful');
        return response.text().then((content) => {
          localStorage.setItem('access-token', JSON.parse(content).access);
          localStorage.setItem('refresh-token', JSON.parse(content).refresh);
          return true;
        });
      case 401:
        console.log('Status: 401 \t Obtaining tokens failed');
        return false;
      default:
        console.error('Reached default in login-switch');
        break;
    }
  });
};

let refreshing = false;

const refreshToken = () => {
  if (!refreshing) {
    refreshing = true;
    console.log('Refreshing token');
    // let url = "https://easymove-be.herokuapp.com/dashapi/token/refresh/"
    // let url ="https://easymove-staging.herokuapp.com/dashapi/token/refresh/"
    let url = ApiBaseUrl + '/dashapi/token/refresh/';
    let data = {
      refresh: localStorage.getItem('refresh-token'),
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return fetch(url, options).then((response) => {
      switch (response.status) {
        case 200:
          console.log('Status: 200 \t Refreshing token successful');
          refreshing = false;
          return response.text().then((content) => {
            console.log(content);
            localStorage.setItem('access-token', JSON.parse(content).access);
            return true;
          });
        case 401:
          console.log('Status: 401 \t Refreshing token failed');
          refreshing = true;
          window.location = '/';
          return false;
        default:
          console.error(
            'Reached default in refresh-switch with status code: ' +
              response.status
          );
          refreshing = false;
          console.error(response);
          break;
      }
    });
  }
};

const fetchFromServer = async (method, path, data) => {
  console.log('Fetching from server');
  // let url = "https://easymove-be.herokuapp.com/";
  // let url ="https://easymove-staging.herokuapp.com/"
  let url = ApiBaseUrl + '/';
  let options = {
    method,
    body: typeof data !== 'undefined' ? JSON.stringify(data) : void 0,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access-token'),
      'Content-Type': 'application/json',
    },
  };

  console.log(options);
  console.log(url + path);

  let response;
  response = await fetch(url + path, options);
  switch (response.status) {
    case 200:
      console.log('Fetching from server successful');
      return response.text();
    case 401:
      console.log('Fetching from server failed');
      await refreshToken();
      console.log('Trying fetch again after refresh');
      return fetchFromServer(method, path, data);
    default:
      console.error('FINAL | Failed fetching from server');
      console.error(response);
      break;
  }
};

export default Api;
