import React, { Component } from 'react'
import {Card, Accordion, Container, Button, Row, Col, FormControl} from 'react-bootstrap'
import Api from '../Api';
import SyncLoader from "react-spinners/SyncLoader";
import logo from '../assets/logo-big.png';



class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            error: false,
            buttonColor: "#CCC",
            loading: false
        }
    }






    getToken = async () => {
        this.setState({
            loading: true
        })

        await Api.getToken(this.state.username, this.state.password).then((result) => {
            if (result) {
                    window.location.href = "/schedules";

            } else {
                this.setState({ error: true, loading: false })
            }
        })
    }

    getFormatFromDate(date) {
        return ("0" + date.getDate()).slice(-2) + ("0" + (date.getMonth() + 1)).slice(-2) + date.getFullYear() + ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2)
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (value != "") {
            this.setState({
                buttonColor: "#3B2E4D"
            })
        } else {
            this.setState({
                buttonColor: "#CCC"
            })
        }

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
          <div style={{backgroundColor: "rgb(41 29 64)", minHeight: "150vh"}}>
          <p style={{ color: "white", fontWeight: "bold", marginTop: "0px", marginBottom: "10px", fontSize: "3rem" }}><img src={logo}/><br/>planner</p>
            <div style={{ textAlign: "center", fontFamily: "Arial", width: "30vw", margin:"auto", paddingTop: 120, }}>

            <div style={{backgroundColor: "#3b2e4d", paddingLeft: "2vw",paddingRight: "2vw", paddingTop: "1vh", paddingBottom: "1vh", minHeight: "35vh", borderRadius: "1.5rem"}}>
            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
              integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
              crossorigin="anonymous"
            />
                <div className="login-container">
                    <p style={{ color: "white", fontWeight: "bold", fontSize: "20px", marginTop: "0px", marginBottom: "10px", fontSize: "4vh" }}>Log ind</p>
                    <form>
                    <FormControl
                        className={this.state.error ? "login-form login-error" : "login-form"}
                        type="text"
                        style={{marginBottom: "2vh", fontSize: "3vh", color: "#3b2e4d"}}
                        name="username"
                        placeholder="Brugernavn"
                        value={this.state.username}
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ error: false })}
                        />

                        <FormControl
                            onKeyPress={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    this.getToken();
                                }
                            }}
                            className={this.state.error ? "login-form login-error" : "login-form"}
                            type="password"
                            name="password"
                            style={{marginBottom: "4vh", fontSize: "3vh", color: "#3b2e4d"}}
                            placeholder="Adgangskode"
                            value={this.state.password}
                            onChange={this.handleChange}
                            onFocus={() => this.setState({ error: false })}
                            formMethod="post"
                        />

                    </form>
                    {this.state.error ? <p style={{ color: "red" }}>Wrong password</p> : void 0}
                    {this.state.loading ?
                        <div style={{marginTop: "2vh"}}>
                            <SyncLoader color="white"/>
                        </div>
                        :
                        <Button
                            style={{ border: "2px solid white !important", fontSize: "2.5vh"}}
                            className="button-primary"
                            onClick={this.getToken}
                        >LOG IND</Button>
                    }

                </div>
                </div>
                <div className="linkText">
                <a href="https://easymove.running26.com/easyadmin/" >Tilbage til easyadmin</a>
                </div>
            </div>
            </div>
        )
    }
}

export default LoginPage
